
















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations, MutationMethod } from 'vuex';

import Error from '@/components/fmh_components/common/Error/Error.vue';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import ModalConfirm from '../../../wettbewerbsvergleich/src/components/common/ModalConfirm/ModalConfirm.vue';
import CreateNewReport from '@/components/fmh_components/common/CreateNewReport/CreateNewReport.vue';
import SnackBar from '@/components/fmh_components/common/SnackBar/SnackBar.vue';
import ChildHeader from '@/components/fmh_components/common/ChildHeader/ChildHeader.vue';

import {
  BASE_BLACK_COLOR,
  SNACK_BAR_DATA,
  TEXT_ACTION_BUTTONS,
} from '@/utils/constants';

@Component({
  components: {
    ModalConfirm,
    Error,
    Loader,
    CreateNewReport,
    SnackBar,
    ChildHeader,
  },
  computed: {
    ...mapGetters({
      uploadedReports: 'bankingProfile/uploadedReports',
      allReports: 'bankingProfile/getReports',
      isAuthorized: 'auth/isAuthorized',
      error: 'bankingProfile/error',
      loading: 'bankingProfile/loading',
      snackbarData: 'bankingProfile/getSnackbarData',
    }),
  },
  methods: {
    ...mapMutations({
      setSnackBarData: 'bankingProfile/setSnackBarData',
      updateAllReports: 'bankingProfile/updateAllReports',
    }),
    ...mapActions({
      deleteReport: 'bankingProfile/deleteReport',
      fetchAllReports: 'bankingProfile/fetchAllReports',
    }),
  },
})
export default class BankingProfile extends Vue {
  isAuthorized!: Boolean;
  color = BASE_BLACK_COLOR;
  allReports!: Array<Object>;
  page = 1;
  itemsPerPage = 7;
  menu = false;
  confirmDialog = false;
  report = {};
  isDeleting = false;
  textActionButtons = TEXT_ACTION_BUTTONS;
  snackBarData = SNACK_BAR_DATA;
  loading!: Boolean;

  setSnackBarData!: MutationMethod;
  updateAllReports!: MutationMethod;
  deleteReport!: Function;
  fetchAllReports!: () => Promise<Object>;

  @Watch('isAuthorized') getData(val) {
    if (val && !this.allReports.length) {
      this.fetchAllReports();
    }
  }
  handleChangeConfirmModal(val) {
    this.confirmDialog = val;
    this.report = {};
  }
  async deleteBPReport() {
    this.isDeleting = true;
    try {
      await this.deleteReport((this.report as any).slug);
      this.updateAllReports((this.report as any).slug);
      this.isDeleting = false;
      this.confirmDialog = false;
      this.report = {};
    } catch (e) {
      this.isDeleting = false;
      this.confirmDialog = false;
      this.report = {};
      this.setSnackBarData(this.snackBarData);
    }
  }
  openConfirmDialog(report) {
    this.report = report;
    this.confirmDialog = true;
  }
  goSingleReport(item) {
    if (
      this.$router.currentRoute.fullPath !==
      `/banking-profile/report/${item.slug}`
    ) {
      this.$router.push(`/banking-profile/report/${item.slug}`).catch(() => {});
    }
  }
  formerPage() {
    if (this.page - 1 >= 1) this.page -= 1;
  }
  nextPage() {
    if (this.page + 1 <= this.numberOfPages()) {
      this.page += 1;
    }
  }
  numberOfPages() {
    return Math.ceil(this.allReports.length / this.itemsPerPage);
  }
  addNewReport() {
    if (
      this.$router.currentRoute.fullPath !== '/banking-profile/add-new-report'
    ) {
      this.$router.push('/banking-profile/add-new-report').catch(() => {});
    }
  }
  validate(item) {
    return item?.rows && item?.rows.length > 0 && !this.loading;
  }

  created() {
    if (this.isAuthorized && !this.allReports.length) {
      this.fetchAllReports();
    }
  }
}
