











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({})
export default class ChildHeader extends Vue {
  @Prop() date!: String;
  @Prop() loading: Boolean | undefined;
  color = BASE_BLACK_COLOR;
}
